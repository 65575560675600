import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { Button, SvgIcon } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const styles = (theme:Theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  name: {
    flexGrow: 1,
    textAlign: 'right'
  },
  links: {
    flexGrow: 1,
    textAlign: 'center'   
  }
});

interface HeaderProps extends WithStyles<typeof styles> {
  name: string
}

const SlackIcon = () => {
  return (
    <SvgIcon>
      <path d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5z" fill="#ffffff"/>
    </SvgIcon>
  );
}

class Header extends Component<HeaderProps> {
  render () {
    const classes = this.props.classes;
    return (
      <div data-testid='header-root' className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography data-testid='header-text' variant="h4" className={classes.title}>
              Play Portal
            </Typography>
            <Typography data-testid='header-links' variant="h6" className={classes.links}>
            <Button data-testid='slack-play-portal-link' href="https://hbo.slack.com/archives/CT8C04JQ0" target="_blank" rel="noopener noreferrer" color="inherit">
              <SlackIcon/>
              play portal
            </Button>
            <Button data-testid='slack-play-portal-events-link' href="https://hbo.slack.com/archives/CV6TB3HBP" target="_blank" rel="noopener noreferrer" color="inherit">
              <SlackIcon/>
              play portal events
            </Button>
            <Button data-testid='slack-play-portal-support-link' href="https://hbo.slack.com/archives/C010950UDC0" target="_blank" rel="noopener noreferrer" color="inherit">
              <SlackIcon/>
              play portal support
            </Button>
            <Button data-testid='faq-link' href="https://wiki.hbo.com/pages/viewpage.action?pageId=120216285" target="_blank" rel="noopener noreferrer" color="inherit">
              <HelpOutlineIcon/>
              faq
            </Button>
            </Typography>
            <Typography data-testid='header-name-text' variant="h6" className={classes.name}>
              {this.props.name}
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Header);
