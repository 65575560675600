import React, {Component} from 'react';
import './Home.css';
import Header from './Header';
import DeploymentsTable from './DeploymentsTable';
import DeploymentForm from './DeploymentForm';
import { listConversations, listDeployments, listRecordSets } from '../api';
import { withAuth } from '@okta/okta-react';
import { Auth } from '../auth';
import { CircularProgress } from '@material-ui/core';
import RecordSetsTable from './RecordSetsTable';
import RecordSetForm from './RecordSetForm';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LogStreamsComponent from './LogEvents';
import LogStreamsTable from './LogEvents';
export interface HomeProps {
  auth: Auth
}

class Home extends Component<HomeProps> {
  state = {
    deployments: [],
    conversations: [], 
    recordSets: [],
    loading: true,
    loadingConversations: true,
    user: { name: '', email: '' },
    tab: 0
  }

  async updateDeployments() {
    const deployments = await listDeployments().catch(console.log);
    this.setState({ loading: true });
    this.setState({ deployments });
    this.setState({ loading: false });
  }

  async updateUser() {
    const user = await this.props.auth.getUser();
    this.setState({ user });
  }

  async updateConversations() {
    const conversations = await listConversations().catch(console.log);
    this.setState({ loadingConversations: true });
    this.setState({ conversations });
    this.setState({ loadingConversations: false });
  }

  async updateRecordSets() {
    const recordSets = await listRecordSets().catch(console.log);
    this.setState({ loading: true });
    this.setState({ recordSets });
    this.setState({ loading: false });
  }

  async componentDidMount() {
    this.setState({ loading: true, loadingConversations: true });
    await Promise.all([this.updateDeployments(), this.updateConversations(), this.updateUser(), this.updateRecordSets()])
    this.setState({ loading: false, loadingConversations: false });
  }

  handleTabChange = (event: any, newValue: number) => {
    this.setState({ tab: newValue });
  };

  render () {
    const { loading, loadingConversations, deployments, recordSets, user, conversations, tab } = this.state;
    return (
      <div data-testid='home-root' className="Home">
        <Header name={user.name}/>
        <Paper className='Root'>
          <Tabs
            value={tab}
            onChange={this.handleTabChange}
            indicatorColor='primary'
            textColor='primary'
            centered
          >
            <Tab label='Deployments' data-testid='deployments-tab' />
            <Tab label='Record Sets' data-testid='record-sets-tab' />
          </Tabs>
        </Paper>
        { tab === 0 && 
          <div>
            <DeploymentForm loadingConversations={loadingConversations} conversations={conversations} updateDeployments={this.updateDeployments.bind(this)}/>
            {loading ? 
              <header data-testid='loading-header' className="Home-header">
                Loading <CircularProgress />
              </header> :
                <DeploymentsTable user={user} rows={deployments} conversations={conversations} defaultGrouped={true} /> 
            }
            </div>
        } 
        { tab === 1 && 
          <div>
            <RecordSetForm updateRecordSets={this.updateRecordSets.bind(this)} deployments={deployments} /> 
            {loading ? 
              <header data-testid='loading-header' className="Home-header">
                Loading <CircularProgress />
              </header> :
                <RecordSetsTable rows={recordSets} /> 
            }
          </div>
        }
      </div>
    );
  }
}

export default withAuth(Home);
