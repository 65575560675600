import { Owner } from "./api";

export interface Auth {
  login(redirectUri: string): {}
  logout(redirectUri: string): {}
  isAuthenticated(): boolean
  getAccessToken(): string
  getUser(): Owner
}

export const getAccessToken = (): string|undefined => {
  const oktaTokenStorage = localStorage.getItem('okta-token-storage');
  if (!oktaTokenStorage) return undefined;
  const accessToken = JSON.parse(oktaTokenStorage).accessToken;
  return accessToken && accessToken.accessToken;
}

export const authConfig = Object.freeze({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  redirect_uri: window.location.origin + '/implicit/callback',
  client_id: process.env.REACT_APP_OKTA_CLIENT_ID,
  pkce: true
});
