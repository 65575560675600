import React, {Component} from 'react';
import './Home.css';
import Header from './Header';
import { Deployment, getDeployment, listConversations } from '../api';
import { withAuth } from '@okta/okta-react';
import { Auth } from '../auth';
import { CircularProgress, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { RouteComponentProps } from 'react-router-dom';
import DeploymentDetails from './DeploymentDetails';

export interface DeploymentDetailsPageProps {
  auth: Auth
}

interface IReactRouterParams {
  stackName: string;
  username: string;
}

class DeploymentDetailsPage extends Component<DeploymentDetailsPageProps & RouteComponentProps<IReactRouterParams>> {
  state = {
    stackName: '',
    deployment: {} as Deployment,
    conversations: [],
    loading: true,
    user: { name: '', email: '' },
    error: Error('Something went wrong'),
    failed: false
  }

  async updateDeployment(stackName: string) {
    const deployment = await getDeployment(stackName);
    this.setState({ deployment });
  }

  async updateUser() {
    const user = await this.props.auth.getUser();
    this.setState({ user });
  }

  async updateConversations() {
    const conversations = await listConversations();
    this.setState({ conversations });
  }

  async componentDidMount() {
    const stackName = this.props.match.params.stackName;
    this.setState({ loading: true, failed: false });
    try {
      await Promise.all([this.updateDeployment(stackName), this.updateConversations(), this.updateUser()])
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false, error, failed: true })
    }
  }

  render () {
    const { loading, deployment, user, conversations, error, failed } = this.state;
    return (
      <div data-testid='deployment-details-page-root' className="Home">
        <Header name={user.name}/>
        <Paper className='deployment-details-page'>
          { failed && <Typography color="secondary" component="h1" variant="h5">
              {error.message}
            </Typography>
          }
          { !failed && (loading
            ? <Typography data-testid='deployment-details-page-loading' className='Home-header'>Loading up Deployment Details<CircularProgress /></Typography>
            : <DeploymentDetails user={user} conversations={conversations} deployment={deployment} owners={deployment.owners} slackChannels={deployment.slackChannels} stackId={deployment.stackId} stackName={deployment.stackName} updateDeployment={this.updateDeployment.bind(this, deployment.stackName)} />
          )}
        </Paper>
      </div>
    );
  }
}

export default withAuth(DeploymentDetailsPage);
