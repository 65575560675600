import qs from "qs"
import { Stack, CreateStackOutput, UpdateStackOutput, StackEvents } from 'aws-sdk/clients/cloudformation';
import { GetPipelineStateOutput } from 'aws-sdk/clients/codepipeline';
import { getAccessToken } from './auth';
import { PutItemOutput } from "aws-sdk/clients/dynamodb";
import { ChangeResourceRecordSetsResponse } from "aws-sdk/clients/route53";
import { OutputLogEvents, LogStreams } from "aws-sdk/clients/cloudwatchlogs";

export interface Owner {
  name: string
  email: string
}
export interface Deployment {
  repo: string
  project?: string
  design: string
  stackName: string
  branch: string
  branchCommit?: string
  displayBranchCommit?: string
  stage?: string
  target: string
  stackId: string
  flavor: string
  owners: Owner[]
  displayOwners?: string[]
  status?: string
  slackChannels: string[]
  displaySlackChannels?: string[]
  headerAuthentication?: boolean
  isPublic?: boolean
}

export interface RecordSet {
  name: string
  dnsName: string
  stackName: string
  stackId: string
  secured: boolean
  recordSetOwners: Owner[]
  displayOwners?: string[]
  recordSetState: string
}
export interface ConversationsListResult {
  name: string
  is_private: boolean
}

export type Details = Partial<Stack> & Partial<GetPipelineStateOutput>;

export const listDeployments = async (): Promise<Deployment[]> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVICE_URL + '/listDeployments', {
      headers: {'Authorization': 'Bearer ' + getAccessToken()}
    });
    const deployments = await response.json();
    if (response.ok) {
      return deployments;
    } else {
      throw Error(deployments.error);
    }
  } catch(err) {
    console.log(err);
    throw err;
  }
}

export const listRecordSets = async (): Promise<RecordSet[]> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVICE_URL + '/listRecordSets',{
      headers: {'Authorization': 'Bearer ' + getAccessToken()}
    });
    const recordSets = await response.json();
    if (response.ok) {
      return recordSets;
    } else {
      throw Error(recordSets.error);
    }
  } catch(err) {
    console.log(err);
    throw err;
  }
}

export const listConversations = async (): Promise<ConversationsListResult[]> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVICE_URL + '/listConversations', {
      headers: {'Authorization': 'Bearer ' + getAccessToken()}
    });
    const conversations = await response.json();
    if (response.ok) {
      return conversations;
    } else {
      throw Error(conversations.error);
    }
  } catch(err) {
    console.log(err);
    throw err;
  }
}

export const getDeployment = async (stackName: string): Promise<Deployment|undefined> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVICE_URL +'/getDeployment', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + getAccessToken(), 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: qs.stringify({stackName})
    });
    const deployment = await response.json();
    if (response.ok) {
      return deployment;
    } else {
      throw Error(deployment.error);
    }
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getStackAndPipelineDetails = async (stackId: string, stackName: string): Promise<Details[]> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVICE_URL +'/getStackAndPipelineDetails', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + getAccessToken(), 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: qs.stringify({stackId, stackName})
    });
    const details = await response.json();
    if (response.ok) {
      return details;
    } else {
      throw Error(details.error);
    }
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const updateDeploymentOwners = async (stackId: string, stackName: string, remove: boolean): Promise<PutItemOutput> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVICE_URL +'/updateDeploymentOwners', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + getAccessToken(), 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: qs.stringify({stackId, stackName, remove})
    });
    const result = await response.json();
    if (response.ok) {
      return result;
    } else {
      throw Error(await result.error);
    }
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const updateDeploymentSlackChannels = async (stackId: string, stackName: string, slackChannels: string[]): Promise<PutItemOutput> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVICE_URL +'/updateDeploymentSlackChannels', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + getAccessToken(), 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: qs.stringify({stackId, stackName, slackChannels})
    });
    const result = await response.json();
    if (response.ok) {
      return result;
    } else {
      throw Error(await result.error);
    }
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const startPipeline = async (stackId: string, stackName: string): Promise<{}> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVICE_URL + '/startPipeline', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + getAccessToken(), 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: qs.stringify({stackId, stackName})
    })
    const result = await response.json();
    if (response.ok) {
      return result;
    } else {
      throw Error(result.error);
    }
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const deleteDeployment = async (stackId: string, stackName: string): Promise<{}> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVICE_URL + '/deleteDeployment', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + getAccessToken(), 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: qs.stringify({stackId, stackName})
    })
    const result = await response.json();
    if (response.ok) {
      return result;
    } else {
      throw Error(result.error);
    }
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const requestDeployment = async (repo: string, target: string|null, flavor: string|null, design: string|null, headerAuthentication: boolean, slackChannels: string[], update: boolean, isPublic : boolean, alternativeDomainNames?: string[], branch?: string, project?: string, branchCommit?: string, stage?: string): Promise<CreateStackOutput | UpdateStackOutput> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVICE_URL + '/requestDeployment', {
      method: 'POST', 
      headers: {'Authorization': 'Bearer ' + getAccessToken(), 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}, 
      body: qs.stringify({repo, project, branch, branchCommit, stage, target, flavor, design, headerAuthentication, slackChannels, update, isPublic, alternativeDomainNames})
    });
    const stack = await response.json();
    if (response.ok) {
      return stack;
    } else {
      throw Error(stack.error);
    }
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const changeRecordSet = async (action: string, name: string, stackName: string, secured: boolean): Promise<ChangeResourceRecordSetsResponse> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVICE_URL + '/changeRecordSet', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + getAccessToken(), 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: qs.stringify({action, name, stackName, secured})
    });
    const stack = await response.json();
    if (response.ok) {
      return stack;
    } else {
      throw Error(stack.error);
    }
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getLogStreams = async (stackName: string): Promise<LogStreams> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVICE_URL + '/getLogStreams', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + getAccessToken(), 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: qs.stringify({stackName})
    });
    const logStream = await response.json();
    if (response.ok) {
      return logStream;
    } else {
      throw Error(logStream.error);
    }
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getLogEvents = async (stackName: string, logStreamName: string): Promise<OutputLogEvents> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVICE_URL + '/getLogEvents', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + getAccessToken(), 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: qs.stringify({stackName, logStreamName})
    });
    const logEvents = await response.json();
    if (response.ok) {
      return logEvents;
    } else {
      throw Error(logEvents.error);
    }
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const getStackEvents = async (stackName: string): Promise<StackEvents> => {
  try {
    const response = await fetch(process.env.REACT_APP_SERVICE_URL + '/getStackEvents', {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + getAccessToken(), 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
      body: qs.stringify({stackName})
    });
    const events = await response.json();
    if (response.ok) {
      return events;
    } else {
      throw Error(events.error);
    }
  } catch(err) {
    console.log(err);
    throw err;
  }
};
