import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';

import { withStyles, WithStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from '@material-ui/core/styles';
import { RecordSet } from '../api';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  }
});

interface RecordSetsTableProps extends WithStyles<typeof styles> {
  rows: RecordSet[]
}

interface RecordSetsTableState {
  rows: RecordSet[]
}

class RecordSetsTable extends Component<RecordSetsTableProps, RecordSetsTableState> {
  state = {
    rows: this.props.rows
  }

  // async updateRecordSet(name: string) {
  //   const { rows } = this.state;
  //   const updatedRow = await getDeployment(stackName);
  //   const index = rows.findIndex((row) => row.stackName === updatedRow?.stackName);
  //   if ((index >= 0) && updatedRow) this.props.rows[index] = updatedRow;
  //   this.setState({ rows: this.props.rows });
  // }

  render () {
    const classes = this.props.classes;
    const { rows } = this.state;
    const filteredRows = rows.filter(row => row.recordSetState !== 'DELETE');
    filteredRows.forEach((row) => row.displayOwners = row.recordSetOwners.map(owner => owner.name + ' (' + owner.email + ') '));
    return (
      <Paper data-testid="record-set-table-root" className={classes.root}>
        <MaterialTable
          columns={[
            { title: 'Name', field: 'name' },
            { title: 'Stack Name', field: 'stackName' },
            { title: 'DNS Name', field: 'dnsName', render: (rowData: RecordSet) => (
              <a href={rowData.dnsName} target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">
                {rowData.dnsName}
              </a>
            ) },
            { title: 'Secured', field: 'secured' },
            { title: 'Owners', field: 'displayOwners' },
            { title: 'State', field: 'recordSetState' },
          ]}
          data={filteredRows}
          title="Record Sets"
          options={{
            grouping: true
          }}
        />
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(RecordSetsTable);
