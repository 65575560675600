import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";
import { CircularProgress, Paper, Typography } from "@material-ui/core";
import { LogStreams, OutputLogEvents } from 'aws-sdk/clients/cloudwatchlogs';
import { getLogEvents } from '../api';

interface tableDataInterface {
  logStreamName: string | undefined;
  lastEventTimestamp: number | undefined;
  timeStampString: string;
}

interface tableProps {
  logStreams: LogStreams;
  stackName: string;
}

interface logEventProps {
  logStreamName: string;
  stackName: string;
}

export const sortTime = (a: tableDataInterface, b: tableDataInterface) => a.lastEventTimestamp! - b.lastEventTimestamp!;

export const LogStreamsTable = (props: tableProps) => {
  const { logStreams, stackName } = props
  const [tableData, setTableData] = useState<tableDataInterface[]>([]);

  useEffect(() => {
    const logStreamData = mapLogData(logStreams);
    setTableData(logStreamData);
  }, []);

  const mapLogData = (data: LogStreams) => {
    return data.map((logStream) => {
      const time = new Date(logStream.lastEventTimestamp!);
      return {
        logStreamName: logStream.logStreamName,
        lastEventTimestamp: logStream.lastEventTimestamp,
        timeStampString: time.toString()
      };
    });
  };

  return (
    <Paper>
      <MaterialTable
        title="Build Logs"
        columns={[
          { title: "Log Streams", field: "logStreamName", sorting: false },
          { title: "Last Event Timestamp", field: "timeStampString", defaultSort: 'desc', customSort: sortTime},
        ]}
        data={tableData}
        detailPanel={(rowData) => {
          return <EventComponent logStreamName={rowData.logStreamName!} stackName={stackName}/>;
        }}
        options={{
            sorting: true
        }}
      />
    </Paper>
  );
};

export const EventComponent = (props: logEventProps) => {
  const { stackName, logStreamName } = props;
  const [logEvents, setLogEvents] = useState<OutputLogEvents>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
          const eventLogs: OutputLogEvents = await getLogEvents(stackName, logStreamName);
          setLogEvents(eventLogs);
          setLoading(false);
      };
      setLoading(true);
      fetchData();
  }, []);

    const createEventList = (data: OutputLogEvents) => {
      return data.map((logEvent) => {
        const time = new Date(logEvent.timestamp!);
        return <Typography> {time.toLocaleTimeString()} - {logEvent.message} </Typography>
      });
    };

  const list = createEventList(logEvents);

  return loading ? <Typography data-testid='log-Event-loading-details'> Loading log events <CircularProgress/></Typography> : <>{list}</>
};

export default LogStreamsTable;
