import React, {Component, Fragment} from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Security, ImplicitCallback, SecureRoute } from '@okta/okta-react';
import Home from './Home';
import DeploymentDetailsPage from './DeploymentDetailsPage';
import { authConfig } from '../auth';

class App extends Component {
  render () {
    return (
      <Fragment>
        <Router>
          <Security {...authConfig}>
            <SecureRoute path="/" exact={true} component={Home}/>
            <SecureRoute path="/details/:stackName" component={DeploymentDetailsPage}/>
            <Route path="/implicit/callback" component={ImplicitCallback}/>
          </Security>
        </Router>
      </Fragment>
    );
  }
}

export default App;
