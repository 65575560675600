import React, {Component, Fragment} from 'react';
import Typography from '@material-ui/core/Typography';
import { Button, TextField } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Details, Owner, getStackAndPipelineDetails, startPipeline, deleteDeployment, updateDeploymentOwners, ConversationsListResult, updateDeploymentSlackChannels, requestDeployment, Deployment, getLogStreams, getStackEvents } from '../api'
import { Autocomplete } from '@material-ui/lab';
import { LogStreams } from 'aws-sdk/clients/cloudwatchlogs';
import LogStreamsTable from './LogEvents'
import { StackEvents } from 'aws-sdk/clients/cloudformation';
import MaterialTable from 'material-table';

interface DeploymentDetailsProps {
  stackId: string
  stackName: string
  owners: Owner[]
  slackChannels: string[]
  deployment: Deployment
  conversations: ConversationsListResult[]
  user: Owner
  updateDeployment: Function
}
interface DeploymentDetailsState {
  details: Details[]
  loading: boolean
  buttonsDisabled: boolean
  updateOwnersDisabled: boolean
  buttonsLoading: boolean
  owners: Owner[]
  slackChannels: string[]
  alternativeDomainNames?: string[]
  user: Owner
  isOwner: boolean
  logStreams: LogStreams
  stackEvents: StackEvents
}

class DeploymentDetails extends Component<DeploymentDetailsProps, DeploymentDetailsState> {
  state = {
    details: [{
      StackStatus: '',
      Outputs: [{
        OutputValue: ''
      }],
      Parameters: [{
        ParameterKey: '',
        ParameterValue: ''
      }],
      stageStates: [{
        latestExecution: {
          status: '',
          pipelineExecutionId: ''
        }
      }]
    }],
    loading: true,
    buttonsDisabled: true,
    updateOwnersDisabled: true,
    buttonsLoading: false,
    owners: [],
    slackChannels: [],
    user: {name: '', email: ''},
    isOwner: false,
    alternativeDomainNames: [],
    logStreams: [],
    stackEvents: []
  }

  async handleStartPipeline() {
    const { stackId, stackName } = this.props;
    this.setState({ buttonsDisabled: true, buttonsLoading: true });
    await startPipeline(stackId, stackName ).catch(console.log);
    this.setState({ loading: false, buttonsDisabled: false, buttonsLoading: false });
  }

  async handleDelete() {
    const { stackId, stackName } = this.props;
    this.setState({ buttonsDisabled: true, buttonsLoading: true });
    await deleteDeployment(stackId, stackName).catch(console.log);
    this.setState({ loading: false, buttonsLoading: false });
    this.setState({ details: [] });
  }

  handleSlackChannelsChange = (event: any, values: ConversationsListResult[]) => {
    this.setState({ slackChannels: values.map(value => value.name) });
  }

  handleAlternativeDomainNamesChange = (event: any, values: string[]) => {
    this.setState({ alternativeDomainNames: values });
  }

  async handleDeploymentOwners(isOwner: boolean) {
    const { stackId, stackName, updateDeployment } = this.props;
    this.setState({ buttonsDisabled: true, buttonsLoading: true });
    await updateDeploymentOwners(stackId, stackName, isOwner).catch(console.log);
    await updateDeployment().catch(console.log);
    this.setState({ loading: false, buttonsDisabled: false, buttonsLoading: false, isOwner: !isOwner });
  }

  async handleDeploymentSlackChannels(slackChannels: string[]) {
    const { stackId, stackName, updateDeployment } = this.props;
    this.setState({ buttonsDisabled: true, buttonsLoading: true });
    await updateDeploymentSlackChannels(stackId, stackName, slackChannels).catch(console.log);
    await updateDeployment().catch(console.log);
    this.setState({ loading: false, buttonsDisabled: false, buttonsLoading: false, slackChannels });
  }

  async handleDeploymentAlternativeDomainNames(alternativeDomainNames: string[]) {
    const { deployment, updateDeployment } = this.props;
    this.setState({ buttonsDisabled: true, buttonsLoading: true });
    if (deployment.repo === 'Hadron') {
      await requestDeployment(deployment.repo, deployment.target, deployment.flavor, deployment.design, deployment.headerAuthentication || false, deployment.slackChannels, true, deployment.isPublic || false, alternativeDomainNames, deployment.branch, deployment.project, deployment.branchCommit, deployment.stage).catch(console.log);
    } else {
      await requestDeployment(deployment.repo, null, null, null, deployment.headerAuthentication || false, deployment.slackChannels, true, deployment.isPublic || false, alternativeDomainNames, deployment.branch, deployment.project, deployment.branchCommit, deployment.stage).catch(console.log);
    }
    await updateDeployment().catch(console.log);
    this.setState({ loading: false, buttonsDisabled: false, buttonsLoading: false, alternativeDomainNames });
  }

  async componentDidMount() {
    const { owners, stackId, slackChannels, stackName, user } = this.props;
    const [details, logStreams, stackEvents] = await Promise.all([getStackAndPipelineDetails(stackId, stackName), getLogStreams(stackName).catch(console.log), getStackEvents(stackName)]);
    const isOwner = owners && owners.filter(owner => owner.email === user.email).length > 0;
    const buttonsDisabled = details?.[0]?.StackStatus?.match(/DELETE_COMPLETE|DELETE_IN_PROGRESS/) ? true : false;
    const alternativeDomainNames = details?.[0]?.Parameters?.filter(param => (param?.ParameterKey === 'AlternativeDomainNames' && param?.ParameterValue !== 'noalternativedomainnames'))?.[0]?.ParameterValue?.split(',');
    this.setState({ updateOwnersDisabled: isOwner && (owners?.length === 1) })
    this.setState({ details, loading: false, owners, slackChannels, user, isOwner, buttonsDisabled, alternativeDomainNames, logStreams: logStreams || [], stackEvents});
  }

  render () {
    const { loading, buttonsDisabled, slackChannels, buttonsLoading, details, isOwner, updateOwnersDisabled, alternativeDomainNames, logStreams, stackEvents } = this.state;
    const stackStatus = details?.[0]?.StackStatus;
    const sourceActionStatus = details?.[1]?.stageStates?.[0]?.latestExecution?.status;
    const buildActionStatus = details?.[1]?.stageStates?.[1]?.latestExecution?.status;
    const deploymentUrl = stackStatus?.match(/CREATE_COMPLETE|UPDATE_COMPLETE|UPDATE_IN_PROGRESS/) && "https://" + (details?.[0]?.Outputs?.[0].OutputValue);
    return (
      <div className='Deployment-details'>
        {loading ?
          <Typography data-testid='deployment-loading-details'>
            Loading up Deployment Details <CircularProgress />
          </Typography> :
          <Fragment>
            <Typography data-testid='deployment-details'>
              Stack Status: {stackStatus?.toLocaleUpperCase() || 'NO_STACK_EXISTS'}<br></br>
              Pipeline SourceAction Status: {sourceActionStatus?.toLocaleUpperCase() || 'NO_PIPELINE_STATUS'}<br></br>
              Pipeline BuildAction Status: {buildActionStatus?.toLocaleUpperCase() || 'NO_PIPELINE_STATUS'}<br></br>
              Deployment URL: <span/>
              <Link data-testid='deployment-url' target="_blank" rel="noopener noreferrer" href={deploymentUrl|| undefined}>
                {deploymentUrl || 'NOT_READY' }
              </Link>
            </Typography>
            <Button data-testid='start-pipeline-button' variant="contained" color="primary" disabled={buttonsDisabled} onClick={this.handleStartPipeline.bind(this)}>
              Start Pipeline
            </Button>
            <br></br>
            <Button data-testid='delete-deployment-button' variant="contained" color="secondary" disabled={buttonsDisabled} onClick={this.handleDelete.bind(this)}>
              Delete Deployment
            </Button>
            <br></br>
            <Button data-testid='update-deployment-owners-button' variant="contained" color={isOwner ? 'secondary' : 'primary'} disabled={buttonsDisabled || updateOwnersDisabled} onClick={this.handleDeploymentOwners.bind(this, isOwner)}>
              {isOwner ? 'Remove myself as owner' : 'Add myself as owner'}
            </Button>
            <br></br>
            <Autocomplete data-testid='slackChannels' id="slackChannels" onChange={this.handleSlackChannelsChange} multiple
                options={this.props.conversations}
                getOptionLabel={(option) => option.name}
                defaultValue={slackChannels.map(channel => { return {name: channel, is_private: false}; })}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    variant="standard"
                    label="Slack Channels"
                  />
                )}
              />
            <Button data-testid='update-deployment-slack-channels-button' variant="contained" color={'primary'} disabled={buttonsDisabled} onClick={this.handleDeploymentSlackChannels.bind(this, slackChannels)}>
              Update Deployment Slack Channels
            </Button>
            <Autocomplete data-testid='alternativeDomainNames' id="alternativeDomainNames" onChange={this.handleAlternativeDomainNamesChange} multiple
                options={[] as string[]}
                freeSolo={true}
                defaultValue={alternativeDomainNames}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    variant="standard"
                    label="Alternative Domain Names"
                  />
                )}
              />
            <Button data-testid='update-deployment-alternative-domain-names-button' variant="contained" color={'primary'} disabled={buttonsDisabled} onClick={this.handleDeploymentAlternativeDomainNames.bind(this, alternativeDomainNames)}>
              Update Deployment Alternative Domain Names
            </Button>
            { buttonsLoading && <CircularProgress color='secondary' /> }
            <br></br>
            <LogStreamsTable logStreams={logStreams} stackName={this.props.stackName}/>
            <MaterialTable
              title="Stack Events"
              columns={[
                { title: "Timestamp", field: "Timestamp" },
                { title: "Logical ID", field: "LogicalResourceId" },
                { title: "Status", field: "ResourceStatus" },
                { title: "Status Reason", field: "ResourceStatusReason" },
              ]}
              data={stackEvents}
              options={{
                  pageSize: 20
              }}
            />
          </Fragment>}
      </div>
    );
  }
}

export default DeploymentDetails;
