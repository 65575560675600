import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles, WithStyles } from "@material-ui/core/styles";
import React, {Component} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, Theme } from '@material-ui/core/styles';
import { changeRecordSet, Deployment } from '../api';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, FormControlLabel, Switch } from '@material-ui/core';

const styles = (theme:Theme) => createStyles({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  select: {
    marginTop: theme.spacing(2),
  },
})

interface RecordSetFormProps extends WithStyles<typeof styles> {
  updateRecordSets: Function,
  deployments: Deployment[]
}
interface RecordSetFormState {
  action: string
  name: string
  stackName: string
  secured: boolean
  submitted: boolean
  failed: boolean
  disabled: boolean
  error: Error,
}

class RecordSetForm extends Component<RecordSetFormProps, RecordSetFormState>  {
  state = {
    action: 'CREATE',
    name: '',
    stackName: '',
    secured: false,
    submitted: false,
    failed: false,
    disabled: false,
    error: Error('Something went wrong'),
  }
  
  handleInputChange = (event: any) => {
    const {name, value, checked, type} = event.target;
    const val = type === 'checkbox' ? checked : value;
    this.setState({ [name]: val } as RecordSetFormState);
  }

  handleStackNameChange = (event: any, value: string|null) => {
    this.setState({ stackName: value as string });
  }
  
  async handleSubmit(event: any) {
    event.preventDefault();
    const {action, name, stackName, secured} = this.state;
    console.log({action, name, stackName, secured});
    this.setState({ disabled: true, failed: false, submitted: false });
    try {
      await changeRecordSet(action, name, stackName, secured);
      await this.props.updateRecordSets().catch(console.log);
      this.setState({ submitted: true, failed: false, disabled: false });
    } catch (error) {
      this.setState({  failed: true, submitted: false, disabled: false, error })
    }
  };

  render () {
    const classes = this.props.classes
    const { action, secured, submitted, failed, disabled, error } = this.state
    const { deployments } = this.props
    const filteredDeployments = deployments.filter(deployment => deployment.status !== 'DELETE_COMPLETE');
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <PlayForWorkIcon />
          </Avatar>
          <Typography data-testid='record-set-form-header' component="h1" variant="h5">
            Change Record Set
          </Typography>
          <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
            <FormControl className={classes.formControl}>
              <InputLabel id="action-label">
                Action
              </InputLabel>
              <Select data-testid='action' labelId="action-label" id="action" value={action} fullWidth required name="action" onChange={this.handleInputChange}>
                <MenuItem value="CREATE">CREATE</MenuItem>
                <MenuItem value="UPSERT">UPSERT</MenuItem>
                <MenuItem value="DELETE">DELETE</MenuItem>
              </Select>   
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField inputProps={{ 'data-testid': 'name-input' }} data-testid='name' required fullWidth id="branch" label="Name" name="name" autoComplete="name" onChange={this.handleInputChange}/>
            </FormControl>
              <FormControl className={classes.formControl}>
                <Autocomplete data-testid='stackName' id="stackName" onChange={this.handleStackNameChange} multiple={false} 
                  options={filteredDeployments.map(deployment => deployment.stackName)}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      variant="standard"
                      label="Stack Name"
                    />
                  )}
                />           
              </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={<Switch data-testid='secured' checked={secured} onChange={this.handleInputChange} name="secured" />}
                label="Secured"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Button data-testid='submit-button' type="submit" fullWidth variant="contained" color="primary" disabled={disabled} className={classes.submit}>
                Submit
              </Button>
            </FormControl>
          </form> 
          {disabled && <CircularProgress /> }
          {failed &&     
            <Typography color="secondary" component="h1" variant="h5">
              {error.message}
            </Typography>
          }
          {submitted &&     
            <Typography color="secondary" component="h1" variant="h5">
              Request submitted successfully.
            </Typography>
          }
        </div>
      </Container>
    );
  }
}

export default withStyles(styles, { withTheme: true })(RecordSetForm);
