import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';

import { withStyles, WithStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from '@material-ui/core/styles';
import { Deployment, Owner, getDeployment, ConversationsListResult } from '../api';
import DeploymentDetails from './DeploymentDetails';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  }
});

interface DeploymentsTableProps extends WithStyles<typeof styles> {
  rows: Deployment[]
  user: Owner,
  defaultGrouped: boolean
  conversations: ConversationsListResult[]
}

interface DeploymentsTableState {
  rows: Deployment[]
}

class DeploymentsTable extends Component<DeploymentsTableProps, DeploymentsTableState> {
  state = {
    rows: this.props.rows
  }

  async updateDeployment(stackName: string) {
    const { rows } = this.state;
    const updatedRow = await getDeployment(stackName);
    const index = rows.findIndex((row) => row.stackName === updatedRow?.stackName);
    if ((index >= 0) && updatedRow) this.props.rows[index] = updatedRow;
    this.setState({ rows: this.props.rows });
  }

  render () {
    const classes = this.props.classes;
    const { rows } = this.state;
    const { user, defaultGrouped, conversations } = this.props;
    const filteredRows = rows.filter(row => row.status !== 'DELETE_COMPLETE');
    filteredRows.forEach((row) => row.displayOwners = row.owners.map(owner => owner.name + ' (' + owner.email + ') '));
    filteredRows.forEach((row) => row.displaySlackChannels = row.slackChannels.map(channel => channel + ' '));
    filteredRows.forEach((row) => row.headerAuthentication = !!row.headerAuthentication);
    filteredRows.forEach((row) => row.displayBranchCommit = `https://github.com/HBOCodeLabs/${row.repo}/commit/${row.branchCommit}`);
    return (
      <Paper data-testid="deployment-table-root" className={classes.root}>
        <MaterialTable
          columns={[
            { title: 'Stack Name', field: 'stackName' },
            { title: 'Branch Commit', field: 'displayBranchCommit', render: (rowData: Deployment) => (
              <a href={rowData.displayBranchCommit} target="_blank" style={{ textDecoration: 'none' }} rel="noreferrer">
                {rowData.branchCommit}
              </a>
            ) },
            { title: 'Stage', field: 'stage' },
            { title: 'Owners', field: 'displayOwners' },
            defaultGrouped ? { title: 'Repository Name', field: 'repo', defaultGroupOrder: 0, defaultGroupSort: 'desc' }: { title: 'Repository Name', field: 'repo' },
            { title: 'Slack Channels', field: 'displaySlackChannels' },
            { title: 'Header Authentication', field: 'headerAuthentication' },
            defaultGrouped ? { title: 'Branch Name', field: 'branch', defaultGroupOrder: 1, defaultGroupSort: 'desc' } : { title: 'Branch Name', field: 'branch' },
            defaultGrouped ? { title: 'Target', field: 'target', defaultGroupOrder: 2 } : { title: 'Target', field: 'target' },
            { title: 'Flavor', field: 'flavor' },
            defaultGrouped ? { title: 'Design', field: 'design', defaultGroupOrder: 3 } : { title: 'Design', field: 'design' }
          ]}
          data={filteredRows}
          title="Deployments"
          detailPanel={row => {
            return (
              <DeploymentDetails user={user} conversations={conversations} deployment={row} owners={row.owners} slackChannels={row.slackChannels} stackId={row.stackId} stackName={row.stackName} updateDeployment={this.updateDeployment.bind(this, row.stackName)} />
            )
          }}
          options={{
            grouping: true,
            paging: !defaultGrouped
          }}
        />
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DeploymentsTable);
