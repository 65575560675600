import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles, WithStyles } from "@material-ui/core/styles";
import React, {Component} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, Theme } from '@material-ui/core/styles';
import { ConversationsListResult, requestDeployment } from '../api';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, FormControlLabel, Switch } from '@material-ui/core';

const styles = (theme:Theme) => createStyles({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  select: {
    marginTop: theme.spacing(2),
  },
})

interface DeploymentFormProps extends WithStyles<typeof styles> {
  updateDeployments: Function
  conversations: ConversationsListResult[]
  loadingConversations: boolean
}
interface DeploymentFormState {
  repo: string
  project: string,
  branch?: string
  branchCommit?: string
  stage?: string
  target: string
  flavor: string
  design: string
  slackChannels: string[]
  alternativeDomainNames: string[]
  isPublic: boolean
  headerAuthentication: boolean
  submitted: boolean
  failed: boolean
  disabled: boolean
  error: Error,
  release: boolean
}

class DeploymentForm extends Component<DeploymentFormProps, DeploymentFormState>  {
  state = {
    repo: 'Hadron',
    project: 'web',
    branch: undefined,
    branchCommit: undefined,
    stage: 'qa',
    target: 'hbomax',
    flavor: 'int',
    design: 'desktop',
    slackChannels: ['play-portal-events'],
    headerAuthentication: false,
    isPublic: false,
    alternativeDomainNames: [],
    submitted: false,
    failed: false,
    disabled: false,
    error: Error('Something went wrong'),
    release: false
  }
  
  handleInputChange = (event: any) => {
    const {name, value, checked, type} = event.target;
    const val = type === 'checkbox' ? checked : value;
    this.setState({ [name]: val } as DeploymentFormState);
  }

  handleSlackChannelsChange = (event: any, values: ConversationsListResult[]) => {
    this.setState({ slackChannels: values.map(value => value.name) });
  }

  handleAlternativeDomainNamesChange = (event: any, values: string[]) => {
    this.setState({ alternativeDomainNames: values });
  }
  
  async handleSubmit(event: any) {
    event.preventDefault();
    const {repo, project, branch, branchCommit, stage, target, flavor, design, headerAuthentication, isPublic, slackChannels, alternativeDomainNames} = this.state;
    console.log({repo, project, branch, branchCommit, stage, target, flavor, design, slackChannels});
    this.setState({ disabled: true, failed: false, submitted: false });
    try {
      if (repo === 'Hadron') {
        branchCommit ? await requestDeployment(repo, target, flavor, design, headerAuthentication, slackChannels, false, isPublic, alternativeDomainNames, branch, project, branchCommit, stage) :
        await requestDeployment(repo, target, flavor, design, headerAuthentication, slackChannels, false, isPublic, alternativeDomainNames, branch, project);
      } else {
        branchCommit ? await requestDeployment(repo, null, null, null, headerAuthentication, slackChannels, false, isPublic, alternativeDomainNames, branch, project, branchCommit, stage) :
        await requestDeployment(repo, null, null, null, headerAuthentication, slackChannels, false, isPublic, alternativeDomainNames, branch, project);
      }
      
      await this.props.updateDeployments().catch(console.log);
      this.setState({ submitted: true, failed: false, disabled: false });
    } catch (error) {
      this.setState({  failed: true, submitted: false, disabled: false, error })
    }
  };

  render () {
    const classes = this.props.classes
    const { repo, project, target, flavor, design, stage, release, headerAuthentication, isPublic, alternativeDomainNames, submitted, failed, disabled, error } = this.state
    const { conversations, loadingConversations } = this.props
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <PlayForWorkIcon />
          </Avatar>
          <Typography data-testid='deployment-form-header' component="h1" variant="h5">
            Request Deployment
          </Typography>
          <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
            <FormControl className={classes.formControl}>
              <InputLabel id="repo-label">
                Repository Name
              </InputLabel>
              <Select data-testid='repo' labelId="repo-label" id="repo" value={repo} fullWidth required name="repo" onChange={this.handleInputChange}>
                <MenuItem value="Hadron">Hadron</MenuItem>
                <MenuItem value="Codex">Codex</MenuItem>
                <MenuItem value="youi-platform-docs">youi-platform-docs</MenuItem>
                <MenuItem value="Gluon">Gluon</MenuItem>
                <MenuItem value="Gluon-Android">Gluon-Android</MenuItem>
                <MenuItem value="Hindenburg">Hindenburg</MenuItem>
                <MenuItem value="Photon">Photon</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="project-label">
                  Project
                </InputLabel>
                <Select data-testid='project' label="project-label" id="project" value={project} fullWidth required name="project" onChange={this.handleInputChange}>
                  <MenuItem value="web">web</MenuItem>
                  { repo === 'Codex' && <MenuItem value="preview">curation-preview</MenuItem> }
                  { repo === 'Codex' && <MenuItem value="lightning">lightning</MenuItem> }
                </Select>   
              </FormControl>
            <FormControl className={classes.formControl}>
              <TextField inputProps={{ 'data-testid': 'branch-input' }} data-testid='branch' required fullWidth id="branch" label="Branch Name" name="branch" autoComplete="branch" onChange={this.handleInputChange}/>
            </FormControl>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={<Switch data-testid='release' checked={release} onChange={this.handleInputChange} name="release" />}
                label="Release"
              />
            </FormControl>
            { release && 
              <FormControl className={classes.formControl}>
                <TextField inputProps={{ 'data-testid': 'branchCommit-input' }} data-testid='branchCommit' required fullWidth id="branchCommit" label="Branch Commit" name="branchCommit" autoComplete="branchCommit" onChange={this.handleInputChange}/>
              </FormControl>
            }
            { release && 
              <FormControl className={classes.formControl}>
                <InputLabel id="stage-label">
                  Stage
                </InputLabel>
                <Select data-testid='stage' labelId="stage-label" id="stage" value={stage} fullWidth required name="stage" onChange={this.handleInputChange}>
                  <MenuItem value="ci">ci</MenuItem>
                  <MenuItem value="qa">qa</MenuItem>
                  <MenuItem value="staging">staging</MenuItem>
                  <MenuItem value="prod">prod</MenuItem>
                  <MenuItem value="snp">snp</MenuItem>
                  <MenuItem value="qaprod">qaprod</MenuItem>
                  <MenuItem value="alpha">alpha</MenuItem>
                  <MenuItem value="beta">beta</MenuItem>
                  <MenuItem value="gamma">gamma</MenuItem>
                </Select>   
              </FormControl>
            }
            { release && stage === 'prod' &&
              <FormControl className={classes.formControl}>
                <FormControlLabel
                control={<Switch data-testid='isPublic' checked={isPublic} onChange={this.handleInputChange} name="isPublic" />}
                label="Public"
              /> 
              </FormControl>
            }
            <FormControl className={classes.formControl}>
              <Autocomplete data-testid='alternativeDomainNames' id="alternativeDomainNames" onChange={this.handleAlternativeDomainNamesChange} multiple 
                options={[] as string[]}
                freeSolo={true}
                defaultValue={alternativeDomainNames}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    variant="standard"
                    label="Alternative Domain Names"
                  />
                )}
              />           
            </FormControl>
            { repo === 'Hadron' && 
              <FormControl className={classes.formControl}>
                <InputLabel id="target-label">
                  Target
                </InputLabel>
                <Select data-testid='target' label="target-label" id="target" value={target} fullWidth required name="target" onChange={this.handleInputChange}>
                  <MenuItem value="hbomax">hbomax</MenuItem>
                  <MenuItem value="hbomax_latam">hbomax_latam</MenuItem>
                  <MenuItem value="maxgo">maxgo</MenuItem>
                  <MenuItem value="hbonow">hbonow</MenuItem>
                  <MenuItem value="hbogo">hbogo</MenuItem>
                  <MenuItem value="hbomaxact">hbomaxact</MenuItem>
                  <MenuItem value="hbogoact">hbogoact</MenuItem>
                  <MenuItem value="hbonowact">hbonowact</MenuItem>
                  <MenuItem value="hbonowcmr">hbonowcmr</MenuItem>
                </Select>   
              </FormControl>
            }
            { repo === 'Hadron' && 
              <FormControl className={classes.formControl}>
                <InputLabel id="flavor-label">
                  Flavor
                </InputLabel>
                <Select data-testid='flavor' labelId="flavor-label" id="flavor" value={flavor} fullWidth required name="flavor" onChange={this.handleInputChange}>
                  <MenuItem value="int">int</MenuItem>
                  <MenuItem value="prod">prod</MenuItem>
                </Select>   
              </FormControl>
            }
            { repo === 'Hadron' &&             
              <FormControl className={classes.formControl}>
                <InputLabel id="design-label">
                  Design
                </InputLabel>
                <Select data-testid='design' label="design-label" id="design" value={design} fullWidth required name="design" onChange={this.handleInputChange}>
                  <MenuItem value="desktop">desktop</MenuItem>
                  <MenuItem value="activation">activation</MenuItem>
                  <MenuItem value="android">android</MenuItem>
                  <MenuItem value="androidtv">androidtv</MenuItem>
                  <MenuItem value="appletv">appletv</MenuItem>
                  <MenuItem value="commerce">commerce</MenuItem>
                  <MenuItem value="hiltontv">hiltontv</MenuItem>
                  <MenuItem value="ios">ios</MenuItem>
                  <MenuItem value="ps4">ps4</MenuItem>
                  <MenuItem value="tizentv">tizentv</MenuItem>
                  <MenuItem value="uwptv">uwptv (Xbox One)</MenuItem>
                </Select>   
              </FormControl>
            }
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={<Switch data-testid='headerAuthentication' checked={headerAuthentication} onChange={this.handleInputChange} name="headerAuthentication" />}
                label="Header Authentication"
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Autocomplete data-testid='slackChannels' id="slackChannels" fullWidth onChange={this.handleSlackChannelsChange} multiple 
                options={conversations}
                getOptionLabel={(option) => option.name}
                defaultValue={[{name: 'play-portal-events', is_private: false } as ConversationsListResult]}
                loading={loadingConversations}
                ListboxProps={{'data-testid': 'slackChannels-listbox'}}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    variant="standard"
                    label="Slack Channels"
                  />
                )}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Button data-testid='submit-button' type="submit" fullWidth variant="contained" color="primary" disabled={disabled} className={classes.submit}>
                Submit
              </Button>
            </FormControl>
          </form> 
          {disabled && <CircularProgress /> }
          {failed &&     
            <Typography color="secondary" component="h1" variant="h5">
              {error.message}
            </Typography>
          }
          {submitted &&     
            <Typography color="secondary" component="h1" variant="h5">
              Request submitted successfully.
            </Typography>
          }
        </div>
      </Container>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DeploymentForm);
